<template>
  <Feedback :id="id" hide-pagination endpoint="teaching.learners">
    <template #avatar="{ review }">
      <VImage
        :color="review?.feedbackable?.colour"
        :name="getText(review?.feedbackable?.texts, 'name')"
      />
    </template>

    <template #name="{ review }">
      <div class="uppercase font-light text-xs">
        {{ getType(review?.feedbackable_type) }}
      </div>

      {{ getText(review?.feedbackable?.texts, "name") }}
    </template>
  </Feedback>
</template>

<script>
import { computed } from "vue";
import { useI18n } from "vue-i18n";
// Composables
import useTexts from "@/composables/useTexts";
// Components
import Feedback from "@/components/templates/Feedback";
import VImage from "@/components/VImage";

export default {
  components: {
    Feedback,
    VImage
  },
  props: {
    id: {
      type: [Number, String],
      required: true
    }
  },
  setup(props) {
    // Misc
    const { t } = useI18n();

    // Composables
    const { getText } = useTexts();

    // Computed
    const documentTitle = computed(() => {
      // eslint-disable-next-line
      const title = `${t("app.feedback")} - ${t("app.learners", 2)} - ${t("app.teaching")}`;

      const { firstname, lastname } = props.resource?.firstname ?? {};
      if (firstname && lastname) {
        return `${firstname} ${lastname} - ${title}`;
      }

      return title;
    });

    // Methods
    const getType = type => {
      const types = {
        "App\\Models\\Evaluation": t("app.evaluations"),
        "App\\Models\\Collection": t("app.collections"),
        "App\\Models\\Lesson": t("app.lessons"),
        "App\\Models\\Training": t("app.trainings")
      };

      return types[type] ?? "";
    };

    return {
      documentTitle,
      getType,
      // useTexts
      getText
    };
  }
};
</script>
